<template>
  <div>
    <div class="mx-auto max-w-xl mt-8">
      <div v-if="inquiry" class="mb-16">
        <div class="bg-white py-4 shadow rounded">
          <p class="px-4 mb-2 text-base">Sélectionner les destinataires qui reçoivent le message de suivi (sans suite)</p>
          <table class="w-full">
            <tbody>
              <tr
                v-for="rcpt in recipients"
                :key="rcpt.id"
                class="hover:bg-gray-50 text-sm leading-5 font-normal"
                :class="disabled ? 'text-gray-400' : 'text-gray-700'"
              >
                <td class="px-4 py-1">
                  <input
                    v-model="recipientCloseIds"
                    :value="rcpt.id"
                    type="checkbox"
                    class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                    :disabled="disabled"
                  />
                </td>
                <td class="py-1">{{ rcpt.company }}</td>
                <td class="py-1">{{ rcpt.name }}</td>
                <td class="py-1">{{ rcpt.email }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { fromPairs, contains } from 'ramda';

import broker from '../broker';
import SqrButton from '@/sqrd-ui/SqrButton';
import SqrErrorBanner from '@/sqrd-ui/SqrErrorBanner';

import nanoRef from '@/utils/nanoRef';
import stamp from '@/utils/stamp';

export default {
  name: 'InquiryEnd',
  mixins: [broker],
  components: { SqrButton, SqrErrorBanner },
  props: {
    iid: String
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('inquiry', { inquiry: 'doc' }),
    ...mapState('inquiry', ['loading', 'loadError', 'path']),
    recipients() {
      const all = this.inquiry?.model?.recipients ?? [];
      const recipientIds = this.inquiry?.recipientIds ?? [];
      return all.filter(({ id }) => contains(id, recipientIds));
    },
    recipientCloseIds: {
      get() {
        return this.inquiry?.recipientCloseIds ?? [];
      },
      set(value) {
        this.fieldSet('recipientCloseIds', value);
      }
    },
    disabled() {
      return !this.inquiry?.status === 'sent';
    }
  },
  data() {
    return {
      sending: false,
      sendError: null
    };
  },
  methods: {
    ...mapActions('updater', ['update']),
    fieldSet(name, value) {
      this.update({ path: this.path, doc: { [name]: value } });
    }
  },
  metaInfo() {
    const name = this.inquiry?.name;
    return {
      title: `${name} - suivi de la demande d'offre`
    };
  }
};
</script>
